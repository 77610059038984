@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap");

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8f8f8;
  animation: fadeInAnimation ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  overflow-x: hidden;
}

/* NAV */

nav {
  padding: 6px 40px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;
}

.logo {
  font-size: 1.8rem;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: 700;
  transition: all 0.3s ease;
  cursor: pointer;
}

a:hover {
  color: #ff00a9;
  transform: scale(1.075);
}

li {
  list-style: none;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  margin: 0 8px;
}

.menu-icon {
  font-size: 36px !important;
  display: none !important;
  z-index: 100 !important;
}

@media (max-width: 700px) {
  .menu-icon {
    display: block !important;
    z-index: 100 !important;
  }

  .nav-link {
    display: none;
  }
}

/* MODAL */

.container {
  position: relative;
}

.modal-container {
  width: 100%;
  height: 100%;
  background-color: #4b4b4b;
  opacity: 1;
  position: absolute;
  z-index: 1;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInAnimation ease 0.3s;
  /* justify-content: center; */
}

.white {
  color: white !important;
  transition: all 0.3s ease !important;
}

.white:hover {
  color: #ff00a9 !important;
}

.modal-link {
  position: relative;
  margin: 70px 0;
  font-size: 32px;
}

.modal-toggle {
  z-index: 200 !important;
}

/* MAIN */

main {
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  /* border: 1px solid #ff00a9; */
  display: flex;
  flex-direction: column;
}

.location-icon {
  color: red;
  font-size: 18px !important;
}

.main-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  margin-top: 80px;
  display: flex;
}

.main-container-left,
.main-container-right {
  width: 50%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.main-container-left {
  padding: 20px 40px;
}

.main-title {
  font-size: 3rem;
  line-height: 1.25;
}

.cta {
  font-size: 30px !important;
  margin-right: 8px !important;
  margin-top: 20px !important;
}

.main-subtitle {
  margin-top: -10px;
  font-size: 1rem;
  color: gray;
  letter-spacing: 0.5px;
}

.main-subtitle-secondary {
  margin-top: -4px;
  font-size: 0.8rem;
  color: gray;
  display: flex;
}

.main-img {
  width: 60%;
  height: 60%;
  border-end-end-radius: 60%;
  border-end-start-radius: 70%;
  border-start-end-radius: 70%;
  border-start-start-radius: 60%;
  box-shadow: rgba(153, 46, 240, 0.4) -5px 5px,
    rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
  transition: all 0.3s ease;
  margin-left: 5rem;
}

.main-img:hover {
  transform: scale(1.1);
}

.hand-emoji {
  display: inline-block;
  animation: rotateHand 900ms infinite;
}

@media (max-width: 900px) {
  main {
    max-width: unset;
  }


  .main-container {
    max-width: unset;
  }

  .main-container-left {
    width: 100%;
  }
}

@keyframes rotateHand {
  0% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

.tech-stack-container {
  padding: 80px 40px;
  display: flex;
  align-items: center;
}

.vertical-line {
  margin-left: 18px;
  font-weight: 400;
}

.tech-stack {
  margin-left: 40px;
}

.tech-logo {
  width: 30px;
  height: 30px;
  margin: 0 20px;
  transition: all 0.3s ease;
}

.tech-logo:hover {
  transform: scale(1.1);
}

/* FEATURES */

.features-img {
  width: 90%;
  border-radius: 18px;
  transition: all 0.3s ease;
}

.features-img:hover {
  transform: scale(1.1);
}

.features {
  display: flex;
  /* border: 1px solid red; */
  margin-top: 100px;
}

.features-left,
.features-right {
  width: 50%;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.features-subtitle {
  color: #ff00a9;
  font-weight: 700;
  margin-bottom: -26px;
  letter-spacing: 1px;
}

.features-title {
  font-size: 1.5rem;
  margin-bottom: -4px;
  line-height: 1.3;
}

.features-para {
  font-size: 0.9rem;
  color: gray;
  line-height: 1.75;
}

/* PROJECTS */

.projects {
  margin-top: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.project {
  display: flex;
  width: 95%;
  padding: 20px 0;
  /* height: 350px; */
  border-radius: 28px;
  overflow: hidden;
  margin: 140px auto;
  transition: all 300ms ease;
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px,
    rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
}

.project:nth-of-type(2) {
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px,
    rgba(240, 46, 170, 0.05) 25px 25px;
}

.blue {
  color: #ff00a9 !important;
}

.project:hover {
  transform: scale(1.1);
}

.project-img {
  width: 70%;
  margin: 0;
  overflow: hidden;
  border-radius: 8px;
}

.project-info {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
}

.project-title,
.project-subtitle,
.project-desc {
  margin: 0;
}

.project-title {
  font-size: 2.5rem;
}

.project-subtitle {
  font-size: 0.84rem;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin-bottom: 26px;
  color: #ff00a9;
}

.project-desc {
  font-size: 1rem;
  color: gray;
}

.project-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15%;
  margin-top: 12px;
}

.reverse {
  flex-direction: row-reverse;
}

.no-drop {
  cursor: no-drop;
}

/* CONTACT */

.btn-wrapper {
  margin: 0 0 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-btn {
  background-color: #ff00a9;
  color: #fff;
  padding: 12px 82px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.contact-btn:hover {
  background-color: transparent;
  border: 1px solid #ff00a9;
  color: #ff00a9;
  transform: scale(1.05);
}

.shrunk {
  width: 50%;
}

@media (max-width: 1000px) {
  .project {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .project-img {
    border-radius: 8px;
  }

  .project-img,
  .project-info {
    width: 80%;
  }
}

/* FOOTER */

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  background-color: #ff00a9;
  color: white;
}

footer h2 {
  font-size: 2rem;
}

footer a {
  margin: 0 28px;
  transition: all 0.3s ease;
}

footer a:hover {
  color: rgb(219, 219, 219);
}

@media (max-width: 800px) {
  #about,
  .features-right {
    max-width: unset;
    width: 100%;
    margin-bottom: -40px;
  }

  .main-img {
    width: 80%;
    height: 350px;
  }


  .main-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .tech-stack-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: -100px 0;
  }

  .vertical-line {
    display: none;
  }

  .main-title {
    font-size: 2rem;
  }

  #contact {
    margin: 80px 0;
  }

  .main-subtitle {
    font-size: 1rem;
  }

  .main-subtitle-secondary {
    margin: 0;
    text-align: center !important;
  }

  .main-container-left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-container-right {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-title {
    font-size: 1.3rem;
  }

  .project-para {
    font-size: 0.6rem;
  }

  .projects-icon {
    font-size: 1rem !important;
    margin: 0 !important;
  }

  .features-left {
    display: none;
  }

  .features {
    display: flex;
    justify-content: center;
  }

  footer h2 {
    font-size: 1.5rem;
  }

  footer a {
    font-size: 0.7rem;
  }

  footer p {
    font-size: 0.6rem;
  }
}
